import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ac-module-heading',[_vm._v(" PA Reports ")]),_c(VCard,{staticClass:"card-wrapper"},[_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('ac-select',{staticClass:"company-select me-3",attrs:{"label":"Company","items":_vm.companies},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}}),_c('ac-button',{attrs:{"title":"Add","icon":_vm.icons.mdiPlus}})],1),_c(VSpacer),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('ac-input',{staticClass:"staff-list-search me-3",attrs:{"placeholder":"Search","outlined":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('ac-tabs',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"user-reporting",fn:function(){return [_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.userReportHeaders,"items":_vm.userReportList,"options":_vm.userReportOptions,"server-items-length":_vm.totalUserReports,"loading":_vm.loading},on:{"update:options":function($event){_vm.userReportOptions=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var active = ref.item.active;
return [_c(VIcon,{attrs:{"size":"24","color":active ? 'success' : 'error'}},[_vm._v(" "+_vm._s(active ? _vm.icons.mdiCheckboxMarkedCircleOutline : _vm.icons.mdiCloseCircleOutline)+" ")])]}},{key:"item.monthly-alerts",fn:function(ref){
var monthlyAlerts = ref.item.monthlyAlerts;
return [_c(VSwitch,{attrs:{"label":monthlyAlerts ? 'Subscribed' : 'Not Subscribed',"readonly":""},model:{value:(monthlyAlerts),callback:function ($$v) {monthlyAlerts=$$v},expression:"monthlyAlerts"}})]}},{key:"item.actions",fn:function(){return [_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"Send Now","color":"primary","icon":_vm.icons.mdiSend}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"View","color":"success","icon":_vm.icons.mdiEye}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"Remove","color":"error","icon":_vm.icons.mdiDeleteOutline}})]},proxy:true}],null,true)})]},proxy:true},{key:"compliance-officer-reporting",fn:function(){return [_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.complianceOfficerReportHeaders,"items":_vm.complianceOfficerReportList,"options":_vm.complianceOfficerReportOptions,"server-items-length":_vm.totalComplianceOfficerReports,"loading":_vm.loading},on:{"update:options":function($event){_vm.complianceOfficerReportOptions=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var active = ref.item.active;
return [_c(VIcon,{attrs:{"size":"24","color":active ? 'success' : 'error'}},[_vm._v(" "+_vm._s(active ? _vm.icons.mdiCheckboxMarkedCircleOutline : _vm.icons.mdiCloseCircleOutline)+" ")])]}},{key:"item.monthly-alerts",fn:function(ref){
var monthlyAlerts = ref.item.monthlyAlerts;
return [_c(VSwitch,{attrs:{"label":monthlyAlerts ? 'Subscribed' : 'Not Subscribed',"readonly":""},model:{value:(monthlyAlerts),callback:function ($$v) {monthlyAlerts=$$v},expression:"monthlyAlerts"}})]}},{key:"item.actions",fn:function(){return [_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"Send Now","color":"primary","icon":_vm.icons.mdiSend}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"View","color":"success","icon":_vm.icons.mdiEye}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"Remove","color":"error","icon":_vm.icons.mdiDeleteOutline}})]},proxy:true}],null,true)})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }