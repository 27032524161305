import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useReports() {
  const userReportList = ref([])
  const totalUserReports = ref(0)
  const complianceOfficerReportList = ref([])
  const totalComplianceOfficerReports = ref(0)
  const loading = ref(false)
  const searchQuery = ref('')
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')

  const userReportHeaders = [
    { text: 'Username', value: 'username' },
    { text: 'Active', value: 'active' },
    { text: 'Monthly Alerts(sent monthly)', value: 'monthly-alerts' },
    {
      text: 'Other Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const complianceOfficerReportHeaders = [
    { text: 'Compliance Agent Name', value: 'username' },
    { text: 'Active', value: 'active' },
    { text: 'Monthly Alerts(sent monthly)', value: 'monthly-alerts' },
    {
      text: 'Other Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const userReportOptions = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const complianceOfficerReportOptions = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const fetchUserReportList = () => {
    store
      .dispatch('pa-dealing/fetchUserReportList', {
        q: searchQuery.value,
        options: userReportOptions.value,
      })
      .then(data => {
        userReportList.value = data.docs
        totalUserReports.value = data.totalItems

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchComplianceOfficerReportList = () => {
    store
      .dispatch('pa-dealing/fetchComplianceOfficerReportList', {
        q: searchQuery.value,
        options: complianceOfficerReportOptions.value,
      })
      .then(data => {
        complianceOfficerReportList.value = data.docs
        totalComplianceOfficerReports.value = data.totalItems

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, userReportOptions, complianceOfficerReportOptions], () => {
    loading.value = true
    fetchUserReportList()
    fetchComplianceOfficerReportList()
  })

  return {
    userReportHeaders,
    userReportOptions,
    userReportList,
    totalUserReports,
    complianceOfficerReportHeaders,
    complianceOfficerReportOptions,
    complianceOfficerReportList,
    totalComplianceOfficerReports,
    companies,
    selectedCompany,
    searchQuery,
    loading,
  }
}
