<template>
  <div>
    <ac-module-heading> PA Reports </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <ac-select
            label="Company"
            v-model="selectedCompany"
            :items="companies"
            class="company-select me-3"
          />
          <ac-button title="Add" :icon="icons.mdiPlus" />
        </div>
        <v-spacer />
        <div class="d-flex align-center pb-5">
          <ac-input
            v-model="searchQuery"
            placeholder="Search"
            outlined
            class="staff-list-search me-3"
          />
        </div>
      </v-card-text>
      <ac-tabs :tabs="tabs">
        <template #user-reporting>
          <v-data-table
            :headers="userReportHeaders"
            :items="userReportList"
            :options.sync="userReportOptions"
            :server-items-length="totalUserReports"
            :loading="loading"
            class="text-no-wrap"
          >
            <template #[`item.active`]="{ item: { active } }">
              <v-icon size="24" :color="active ? 'success' : 'error'">
                {{ active ? icons.mdiCheckboxMarkedCircleOutline : icons.mdiCloseCircleOutline }}
              </v-icon>
            </template>

            <template #[`item.monthly-alerts`]="{ item: { monthlyAlerts } }">
              <v-switch
                v-model="monthlyAlerts"
                :label="monthlyAlerts ? 'Subscribed' : 'Not Subscribed'"
                readonly
              >
              </v-switch>
            </template>

            <template #[`item.actions`]>
              <ac-icon-btn btn-class="me-1" text="Send Now" color="primary" :icon="icons.mdiSend" />
              <ac-icon-btn btn-class="me-1" text="View" color="success" :icon="icons.mdiEye" />
              <ac-icon-btn
                btn-class="me-1"
                text="Remove"
                color="error"
                :icon="icons.mdiDeleteOutline"
              />
            </template>
          </v-data-table>
        </template>
        <template #compliance-officer-reporting>
          <v-data-table
            :headers="complianceOfficerReportHeaders"
            :items="complianceOfficerReportList"
            :options.sync="complianceOfficerReportOptions"
            :server-items-length="totalComplianceOfficerReports"
            :loading="loading"
            class="text-no-wrap"
          >
            <template #[`item.active`]="{ item: { active } }">
              <v-icon size="24" :color="active ? 'success' : 'error'">
                {{ active ? icons.mdiCheckboxMarkedCircleOutline : icons.mdiCloseCircleOutline }}
              </v-icon>
            </template>

            <template #[`item.monthly-alerts`]="{ item: { monthlyAlerts } }">
              <v-switch
                v-model="monthlyAlerts"
                :label="monthlyAlerts ? 'Subscribed' : 'Not Subscribed'"
                readonly
              >
              </v-switch>
            </template>

            <template #[`item.actions`]>
              <ac-icon-btn btn-class="me-1" text="Send Now" color="primary" :icon="icons.mdiSend" />
              <ac-icon-btn btn-class="me-1" text="View" color="success" :icon="icons.mdiEye" />
              <ac-icon-btn
                btn-class="me-1"
                text="Remove"
                color="error"
                :icon="icons.mdiDeleteOutline"
              />
            </template>
          </v-data-table>
        </template>
      </ac-tabs>
    </v-card>
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcTabs from '@/components/AcTabs.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import store from '@/store'
import padealingStoreModule from '@/store/modules/pa-dealing'
import {
  mdiBellOffOutline,
  mdiBellOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiCloseCircleOutline,
  mdiDeleteOutline,
  mdiEye,
  mdiPlus,
  mdiSend,
} from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import useReports from './composition-apis/reports'

export default {
  components: {
    AcButton,
    AcSelect,
    AcInput,
    AcTabs,
    AcIconBtn,
  },
  setup() {
    const PA_DEALING_STORE_MODULE_NAME = 'pa-dealing'

    // Register module
    if (!store.hasModule(PA_DEALING_STORE_MODULE_NAME)) {
      store.registerModule(PA_DEALING_STORE_MODULE_NAME, padealingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PA_DEALING_STORE_MODULE_NAME))
        store.unregisterModule(PA_DEALING_STORE_MODULE_NAME)
    })

    const tabs = [
      { title: 'User Reporting', content: 'user-reporting' },
      { title: 'Compliance Officer Reporting', content: 'compliance-officer-reporting' },
    ]

    const {
      userReportHeaders,
      userReportOptions,
      userReportList,
      totalUserReports,
      complianceOfficerReportHeaders,
      complianceOfficerReportOptions,
      complianceOfficerReportList,
      totalComplianceOfficerReports,
      searchQuery,
      companies,
      selectedCompany,
      loading,
    } = useReports()

    return {
      tabs,
      userReportHeaders,
      userReportOptions,
      userReportList,
      totalUserReports,
      complianceOfficerReportHeaders,
      complianceOfficerReportOptions,
      complianceOfficerReportList,
      totalComplianceOfficerReports,
      searchQuery,
      companies,
      selectedCompany,
      loading,

      icons: {
        mdiBellOffOutline,
        mdiBellOutline,
        mdiCheckboxMarkedCircleOutline,
        mdiCloseCircleOutline,
        mdiDeleteOutline,
        mdiEye,
        mdiPlus,
        mdiSend,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>
